import $ from 'jquery';

export default class PageNav {
	constructor(options) {

		this._polyfill();
		this.active = null;
		this.elements = [];

		Object.assign(this._options = {}, this._default(), options);
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', () => {
				this.init();
			});
		} else {
			this.init();
		}
	}

	_polyfill() {
		if (!Object.assign) {
			Object.defineProperty(Object, 'assign', {
				enumerable: false,
				configurable: true,
				writable: true,
				value: function (target, firstSource) {
					'use strict';
					if (target === undefined || target === null) {
						throw new TypeError('Cannot convert first argument to object');
					}

					let to = Object(target);
					for (let i = 1; i < arguments.length; i++) {
						let nextSource = arguments[i];
						if (nextSource === undefined || nextSource === null) {
							continue;
						}

						let keysArray = Object.keys(Object(nextSource));
						for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
							let nextKey = keysArray[nextIndex],
								desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
							if (desc !== undefined && desc.enumerable) {
								to[nextKey] = nextSource[nextKey];
							}
						}
					}
					return to;
				}
			});
		}



	}

	_default() {
		return {
			sections: [],		// string ('.element') or object {element: '.element', name: 'my element'}
			offset: 0,			// сдвиг по высоте
			fixOnFooter: false	// при достижении края страницы, делать активным последний элемент
			//-onChange: ()=>{}
			//-onOut: ()=>{}
		}
	}

	getParam(el){
		let doc = document.documentElement,
			left = el ? el.getBoundingClientRect().left + (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0) : (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0),
			top = el ? el.getBoundingClientRect().top + (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0) : (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0),
			width = el ? el.offsetWidth : window.innerWidth,
			height = el ? el.offsetHeight : window.innerHeight
		;

		return {top, left, width, height}
	}

	parseElements(){
		for (let i = 0; i < this._options.sections.length; i++){
			this.elements.push({
				element: typeof this._options.sections[i] === 'string' ? document.querySelectorAll(this._options.sections[i])[0] : document.querySelectorAll(this._options.sections[i].element)[0],
				name: typeof this._options.sections[i] === 'string' ? this._options.sections[i] : this._options.sections[i].name,
			})
		}
	}

	onScroll(){

		let page = this.getParam();

		if ( this._options.fixOnFooter && document.documentElement.scrollHeight <= (page.top + page.height + this._options.offset) ){
			console.info('the end');
			return;
		}

		for (let i = this._options.sections.length - 1; i >= 0; i--){

			let elem = this.getParam(this.elements[i].element),
				onScreen = (elem.top - this._options.offset <= page.top ) && ( elem.top + elem.height - this._options.offset  > page.top)
			;

			if ( onScreen ){
				if ( this.active !== i ){
					this.active = i;
					if (this._options.onChange && typeof this._options.onChange === 'function') this._options.onChange(this.elements[i].name, i);
				}
				break;
			} else if ( !onScreen && this.active === i){
				if (this._options.onOut && typeof this._options.onOut === 'function') this._options.onOut(this.elements[this.active].name, this.active);
				this.active = null;
			}
		}
	}

	init(){

		if ( this._options.sections.length ){
			this.parseElements();
			this.onScroll();
			window.addEventListener("resize", ()=>{this.onScroll()}, false);
			window.addEventListener("scroll", ()=>{this.onScroll()}, false);
		}

	}
}
