import '../css/style.scss'

import $ from 'jquery'
import 'slick-carousel'
import ViewPort from './modules/module.viewport'
import './modules/module.form'
import PageNav from './modules/module.pagenav'
//-import Popup from './modules/module.popup'



$(()=>{


	let $b = $('body'), app = {}, breakpoint;

	let scroll = new PageNav({
		sections: [
			{
				element: '.welcome',
				name: 'none'
			},
			{
				element: '.brands',
				name: 'none'
			},
			{
				element: '.rent',
				name: 'rent'
			},
			{
				element: '.advantages',
				name: 'rent'
			},
			{
				element: '.service',
				name: 'service'
			},
			{
				element: '.contact',
				name: 'contact'
			}
		],
		offset: 120,
		onChange: function(e, i){

			if ( this.activeMenu !== e){
				this.activeMenu = e;

				$('[data-section]').removeClass('is-active');
				$('[data-section=' + this.activeMenu + ']').addClass('is-active');
			}

		},
		onOut: (e)=>{
			//console.info('onOut', e);
		}
	});



	// $b.on('click', '[data-remove]', function (e) {
	// 	e.stopPropagation();
	// 	drugndrop.remove($(this).attr('data-remove'));
	// });

	new ViewPort({
		'0': ()=>{
			breakpoint = 'mobile';
		},
		'1200': ()=>{
			breakpoint = 'desktop';
		}
	});


	$b
		.on('click', '[data-scroll]', function(e){
			e.preventDefault();
			let offset = breakpoint === 'mobile' ? 140 : 20;
			$('html,body').animate({scrollTop: $($(this).attr('data-scroll')).offset().top - offset}, 500);
		})
		.on('click', '.header__handler, .header__menu-item', function(e){
			$b.toggleClass('show-menu')
		})
		.on('click', '.rent__table-group-title', function(e){
			let $group = $(this).closest('.rent__table-group'),
				$inner = $group.find('.rent__table-group-inner')
			;

			if ( !$group.hasClass('buzy') ){
				$group.addClass('buzy');

				$inner.slideToggle(300, ()=>{
					$group.toggleClass('buzy is-open');
					$inner.removeAttr('style');
				})
			}


		})
	;



	$(window).on('scroll', function(){
		let wtop = window.pageYOffset + (window.innerHeight);

		$('.js-graph').each(function () {
			let $t = $(this),
				$parent = $t.parent(),
				top = $parent.offset().top
			;
			$t.css({
				marginTop: (top - wtop ) + 'px'
			});

		});


	});



	$('.brands__list').each(function () {
		let $slider = $(this)
		;

		$slider.slick({
			infinite: true,
			speed: 300,
			swipe: true,
			swipeToSlide: true,
			arrows: true,
			slidesToShow: 6,
			slidesToScroll: 1,
			fade: false,
			nextArrow: `<div class="slick-arrow slick-arrow_next"><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="27px" viewBox="0 0 16 27"><path d="M1.5,27c-0.4,0-0.8-0.2-1.1-0.5c-0.6-0.6-0.5-1.6,0.1-2.1l11.8-10.9L0.5,2.6C-0.1,2-0.2,1.1,0.4,0.5C1-0.1,1.9-0.2,2.5,0.4l13,12c0.3,0.3,0.5,0.7,0.5,1.1s-0.2,0.8-0.5,1.1l-13,12C2.2,26.9,1.9,27,1.5,27z"/></svg></div>`,
			prevArrow: `<div class="slick-arrow slick-arrow_prev"><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="27px" viewBox="0 0 16 27"><path d="M14.5,0c0.4,0,0.8,0.2,1.1,0.5c0.6,0.6,0.5,1.6-0.1,2.1L3.7,13.5l11.8,10.9c0.6,0.6,0.6,1.5,0.1,2.1c-0.6,0.6-1.5,0.6-2.1,0.1l-13-12C0.2,14.3,0,13.9,0,13.5s0.2-0.8,0.5-1.1l13-12C13.8,0.1,14.1,0,14.5,0z"/></svg></div>`,
			variableWidth: false,

			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						variableWidth: true,
					}
				}

			]
		});
	});

	$('.advantages__list').each(function () {
		let $slider = $(this)
		;

		$slider.slick({
			infinite: false,
			speed: 300,
			swipe: true,
			swipeToSlide: true,
			arrows: false,
			slidesToShow: 6,
			slidesToScroll: 1,
			fade: false,
			variableWidth: true,

			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 760,
					settings: {
						slidesToShow: 1,
					}
				}

			]
		});
	});



	ymaps.ready(function () {

		let $map = $('[data-map]'),
			zoom = 16,
			coords = $map.attr('data-map').split(','),
			from = [60.037219, 30.321710],
			to = [60.032502, 30.322392]
		;

		coords[0] = parseFloat(coords[0]);
		coords[1] = parseFloat(coords[1]);

		let map = new ymaps.Map($map[0], {
				center: coords,
				zoom,
				controls: []
			}),
			route = new ymaps.multiRouter.MultiRoute({
				referencePoints: [from,to],
				params: {routingMode: 'pedestrian'}
			}, {
				// Автоматически устанавливать границы карты так, чтобы маршрут был виден целиком.
				//boundsAutoApply: true
				wayPointFinishIconLayout: "default#image",
				wayPointFinishIconImageHref: "../img/svg/balun.svg",
				wayPointFinishIconImageSize: [45, 60],
				wayPointFinishIconImageOffset: [-23, -60],
			});
		map.geoObjects.add(route);

		map.behaviors.disable('scrollZoom');
		map.behaviors.disable('multiTouch');

		// map.behaviors.disable('drag');

		new ViewPort({
			'0': ()=>{
				map.setCenter([coords[0] + 0.0053, coords[1]], zoom);
			},
			'760': ()=>{
				map.setCenter([coords[0] + 0.0023, coords[1] - 0.006], zoom);
			},
			'1200': ()=>{
				map.setCenter([coords[0] + 0.0025, coords[1] - 0.006], zoom);
			}
		});

	});

});







