import $ from 'jquery'
import Forms from './module.validate'
import Inputmask from 'inputmask'

window.app = window.app || {};

$(()=>{

	/*let data = [
		{
			name: 'password',
			error: true,
			success: true,
			message: 'фвфывфы'
		}
	];*/

	let scrollTimer = null;

	class Form extends Forms {
		result (data){
			for (let i = 0; i < data.length; i ++){

				let $input = $(`[name = "${data[i].name}"]`),
					$field = $input.closest('.form__field'),
					$message = $field.find('.form__message')
				;

				if (!$message.length){
					$message = $('<div class="form__message"></div>').appendTo($field.eq($field.length - 1));
				}

				if (data[i].error) $field.addClass('f-error');
				if (data[i].success) $field.addClass('f-success');
				if (data[i].message) {
					$field.addClass('f-message');
					$message.html(data[i].message)
				}
			}

			if ($('.f-error').length) {

				clearTimeout(scrollTimer);
				scrollTimer = setTimeout(()=>{
					let offset = window.app.breakpoint === 'mobile' ? 140 : 20;
					$('html,body').animate({scrollTop: $('.f-error').eq(0).offset().top - offset}, 500);

				}, 200);

			}
		}

		checkFilled(input){
			let $input = $(input),
				$field = $input.closest('.form__field');
			$field.removeClass('f-focused');

			if ( !$.trim($input.val()) ){
				$field.removeClass('f-filled');
			} else {
				$field.addClass('f-filled');
			}
		}
	}

	const form = new Form();

	$('input[data-mask]').each(function () {
		let $t = $(this),
			inputmask = new Inputmask({
				mask: $t.attr('data-mask'),		//'+7 (999) 999-99-99',
				showMaskOnHover: false,
				onincomplete: function() {
					this.value = '';
					$t.closest('.form__field').removeClass('f-filled');
				}
			});
		inputmask.mask($t[0]);
	});

	$('.form__field input').each(function(e){
		if (this.name) form.checkFilled(this);
	});

	$('body')
		.on('click', '.form__input-clear', function (e) {
			let $t = $(this),
				$field = $t.closest('.form__field'),
				$input = $field.find('input')
			;
			$input.val('');
			$field.removeClass('f-filled f-error f-message')

		})
		.on('click', '.form__input-arrow', function (e) {
			let $t = $(this),
				$field = $t.closest('.form__field'),
				$input = $field.find('input')
			;
			$input.focus();

		})
		.on('mousedown', '.form__select-item', function (e) {
			let $t = $(this),
				$field = $t.closest('.form__field'),
				$inputText = $field.find('.form__input-value'),
				$inputValue = $field.find('.form__input-select'),
				value = $t.attr('data-value'),
				text = $t.html()
			;
			$inputText.html(text);
			$inputValue.val(value);
		})

		.on('blur', '.form__field input, .form__field textarea', (e)=>{
			form.checkFilled(e.target)
		})

		.on('change', '[data-require]', function (e) {
			let $t = $(this),
				$input = $(`[name = "${$t[0].name}"]`),
				$field = $input.closest('.form__field');

			$field.removeClass('f-error f-message f-success');

		})

		.on('submit', '.form', function (e) {
			let $form = $(this),
				$item = $form.find('input, textarea').filter(function(){return $(this).is(':visible')}),
				wrong = false
			;

			$item.each(function () {
				let input = $(this), rule = $(this).attr('data-require');

				if ( !input.is(':visible') ) return false;

				$(input)
					.closest('.form__field')
					.removeClass('f-error f-message f-success')
					.find('.form__message')
					.html('')
				;

				if ( rule ){
					form.validate(input[0], rule, err =>{
						if (err.errors.length) {
							wrong = true;
							let data = [{
								name: input[0].name,
								error: true,
								message: err.errors[0]
							}];

							form.result(data);

						}
					})
				}
			});
			if ( wrong ){
				e.preventDefault();
			}
		});


});